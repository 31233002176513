
import { createPage } from '../page';
import { dataLayerEvents, sendStats } from '../stats';
import './checkout.scss';

let root;
let container;
let hubspot;
let hubspotAnchor;


let main_checkout;
export let initilized = false;

export const init = () =>{

  main_checkout = document.getElementById('main_checkout');
  main_checkout.innerHTML = "";
  root = document.createElement('div');
  root.id = "checkoutPage";
  root.classList.add('page');
  main_checkout.appendChild(root);
  container = document.createElement('div');
  container.id = "checkoutPageContainer";
  root.appendChild(container);

  container.classList.add('mpage');
  //console.log('window.data.checkout', window.data.checkout);
  const page = createPage(window.data.checkout);
  container.appendChild(page);

  let spage = page.querySelector('.spage');


  hubspotAnchor = document.createElement('div');
  hubspotAnchor.id = "hubspot-anchor";

  spage.appendChild(hubspotAnchor);

  hubspot = document.createElement('div');
  hubspot.id = "hubspot-checkout";
  container.appendChild(hubspot);

  let el = document.getElementById('hs-script-loader');
  if(!el){

    let script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("charset", "utf-8");
    script.setAttribute("id", "hs-script-loader");
    script.setAttribute("src", "//js-eu1.hsforms.net/forms/embed/v2.js");
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  let buttons = document.createElement('div');
  buttons.classList.add('buttons');
  container.appendChild(buttons);

  let btnBack = document.createElement('a');

  btnBack.classList.add('backBtn');
  btnBack.href = '#';
  btnBack.innerHTML = '<div class="icon"></div>Zurück';
  btnBack.addEventListener('click', (e) => {
    e.preventDefault();
    window.history.back();
  });
  buttons.appendChild(btnBack);



  initilized = true;
}

let to = null;

export function trytoAddForm(){
  clearTimeout(to);
  console.log('trytoAddForm');
  if(typeof window.hbspt !== 'undefined' && document.getElementById('hubspot-checkout'))
    {
      //console.log('hubspot form found');



      window.hbspt.forms.create({
        region: "eu1",
        portalId: "7148443",
        formId: "1ac95bad-fcfb-4717-9f76-2a05ec725857",
        target: "#hubspot-checkout",
        onFormReady: function(form) {
          //console.log('form ready', form);
          //paketauswahl_70_jahre_viebrockhaus
          if(form?.paketauswahl_70_jahre_viebrockhaus){
            form.paketauswahl_70_jahre_viebrockhaus.value = "Folgende Pakete wurden gewählt:\r\n"+ basket.map(i=>" - "+i.item.title).join('\r\n');
          }

          sendStats({evnt:dataLayerEvents.beginCheckout, items:basket.map(i=>i.item)});
        }

      });
      window.addEventListener('message', event => {

        if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
            //someAnalyticsLib('formSubmitted');
            if(event?.data?.data?.submissionValues){
              //console.log('message', event.data.data.submissionValues);
              let d = {
                email:event?.data?.data?.submissionValues.email,
                firstname:event?.data?.data?.submissionValues.firstname,
                lastname:event?.data?.data?.submissionValues.lastname,
                phone:event?.data?.data?.submissionValues.phone,

              }

              sendStats({evnt:dataLayerEvents.generateLead, items:basket.map(i=>i.item), userData:d});
            }
        }
    });
    }else{

      to = setTimeout(trytoAddForm, 100);
    }
}


export function show(){
  console.log('show checkout');
  trytoAddForm();
};
