import './start.css';
const root = document.getElementById('start');
const banner = root.getElementsByClassName('banner')[0];
const banner_title = banner.getElementsByTagName('h1')[0];
const banner_text = banner.getElementsByTagName('p')[0];
//this.banner_title
let video = null;;


const resized = () =>
{
  if(!window.data?.start || !video){
    return;
  }

  if(window.device === "mobile")
    video.src = window.data?.start?.videoMobile || "";
  else
    video.src = window.data?.start?.videoDesktop || "";
}
export function init()
{
  const startText = document.getElementById('starttext');
  const startTextPage = startText.getElementsByClassName('page')[0];

  const bgVideo = root.getElementsByClassName('bg')[0];

  video = bgVideo.querySelector('video');

  if(!video.onclick)
  {
    video.onclick = () =>{
      video.muted = !video.muted;
      if(!video.muted)
        video.play();
      bgVideo.querySelector('.muteIcon').classList.toggle('muted');
    }
  }
  let muteIcon = bgVideo.querySelector('#muteIcon');
  if(!muteIcon){
    muteIcon = document.createElement('div');
    muteIcon.id = "muteIcon";
    muteIcon.classList.add('muted');
    muteIcon.classList.add("muteIcon");
    muteIcon.style.setProperty('--bg-unmuted', 'url(assets/icons/mute.png)');
    muteIcon.style.setProperty('--bg', 'url(assets/icons/unmute.png)');

    bgVideo.appendChild(muteIcon);
  }




  resized();
  /*
  <video autoplay loop muted playsinline src="">

          </video>
  */

  //videoMobile

 // console.log("startText", startTextPage, window.data?.start);

  banner_title.innerHTML = window.data?.start?.banner_title || "";
  banner_text.innerHTML = window.data?.start?.banner_text || "";

  const tx = startTextPage.getElementsByClassName('left-text')[0];
  const imgc = startTextPage.getElementsByClassName('right-image')[0];
  const img = imgc.querySelector('img');
  if(window.device === "desktop" && window.data?.start.pageImage)
    img.src = window.data?.start.pageImage;
  else
    imgc.classList.add("hidden");

  if(window.data?.start?.content && window.data?.start?.content.indexOf("<ol>") === -1)
    window.data.start.content += `<p><ol>
      <li value="1">Jetzt kostenlose Jubiläumspakete auf dieser Seite auswählen</li>
      <li value="2">Klicken Sie den Button: Jubiläumspaket hinzufügen um die Pakete
      im Geschenkekorb abzulegen oder auf Details ansehen um mehr über das Produkt zu erfahren</li>
      <li value="3">Füllen Sie das Kontaktformular aus und versende es noch heute um die Jubiläumspakete zu speichern</li>
      <li value="4">Ihre Jubiläumspakete sind reserviert! Das Haus Ihres Lebens wartet – wir melden uns und begleiten Sie dorthin.</li>

    </ol>
    </p>`

  tx.innerHTML = window.data?.start?.content || "";

  document.addEventListener('deviceChanged', resized);
}



export function show()
{
  video.play();
}
export function hide()
{
  video.pause();
}


