import { syncContent } from './sync_content.js';
import {init as initHeader} from './components/header/header.js';
import {init as initMenu} from './components/menu/menu.js';
import {init as initStart, show as showStart, hide as hideStart} from './components/start/start.js';
import {init as initPackages} from './components/packages/packages.js';
import {init as initRegulations} from './components/regulations/regulations.js';
import {init as initCheckOut, show as showCheckout} from './components/checkout/checkout.js';
import { hide as hideBasket, show as showBasket, init as initBasket} from './components/basket/basket.js';

import { hide as hidePackage, show as showPackage} from './components/package/package.js';
const root = document.getElementById('root');
import './css/styles.scss';
import { dataLayerEvents, sendNavigationStats, sendStats } from './components/stats.js';

history.scrollRestoration = true;

if(navigator.userAgent.toLowerCase().indexOf("windows")>-1)
    document.body.classList.add("windows");

let deviceSettings = [];
const updateDevice = () => {
    document.body.classList.remove("mobile");
    document.body.classList.remove("desktop");
    document.body.classList.remove("portrait");
    document.body.classList.remove("landscape");
    let oldSettings = [...deviceSettings];
    deviceSettings = [];
    if(window.innerWidth<1024){
        deviceSettings.push("mobile");
        window.device = "mobile";
    }
    else{
        deviceSettings.push("desktop");
        window.device = "desktop";
    }

    if(window.innerWidth<window.innerHeight)
        deviceSettings.push("portrait");
    else
        deviceSettings.push("landscape");

    deviceSettings.sort();

    deviceSettings.forEach(setting => {
        document.body.classList.add(setting);
    });

    if(JSON.stringify(oldSettings) !== JSON.stringify(deviceSettings)){
        document.dispatchEvent(new Event("deviceChanged"));
    }
    if(document.body.classList.contains("desktop"))
        document.documentElement.style.setProperty("font-size", "1rem");
    else
        document.documentElement.style.setProperty("font-size", "2.8vw");



    //deviceChanged

}
updateDevice();
window.addEventListener("resize", updateDevice);

const init = () => {
    initMenu();
    initHeader();
    initStart();

    document.getElementById("icon_fb").style.setProperty("--bg", "url(/assets/icons/s_fb.svg)");
    document.getElementById("icon_in").style.setProperty("--bg", "url(/assets/icons/s_in.svg)");
    document.getElementById("icon_pi").style.setProperty("--bg", "url(/assets/icons/s_pi.svg)");
    document.getElementById("icon_yt").style.setProperty("--bg", "url(/assets/icons/s_yt.svg)");
    document.getElementById("icon_ld").style.setProperty("--bg", "url(/assets/icons/s_ld.svg)");

}

let basket = [];

/*
if(localStorage.getItem("basket")){
    localStorage.setItem("basket", JSON.stringify([]));
    try{
        let _basket = JSON.parse(localStorage.getItem("basket"));
        if(Array.isArray(_basket)){
            let sum = 0;
            _basket.forEach(entry => {
                sum += entry.item.price;
                if(sum<=7)
                    basket.push(entry);
            });
        }
    }catch(ex){

    }
}*/



let bannerTimeout=null;
export function startBanner(immediently=false){
  clearTimeout(bannerTimeout);
  bannerTimeout = setTimeout(showBanner, 15000 + Math.random()*30000);

  if(immediently)
    showBanner();
}

function showBanner(){
    fetch("/count.json").then(response => response.json()).then(data => {
        let p = 36;
        try{
            p = data.val;
        }catch(ex){
            p = 36;
        }

        let toastOverlay = document.getElementById("toastOverlay");
        if(toastOverlay?.classList.contains("show"))
            return;

        toast(`${p} Personen haben sich in den letzten 24 Stunden für unsere Jubiläumspakete entschieden!`, "Jetzt Chance ergreifen und Ihre Pakete sichern!", "banner");
    });
}

export function toast(title, text="", state="info"){

    let toastOverlay = document.getElementById("toastOverlay");
    if(!toastOverlay){
        toastOverlay = document.createElement("div");
        toastOverlay.id = "toastOverlay";
        toastOverlay.style.position = "fixed";

        let _text = document.createElement("div");
        _text.classList.add("text");
        toastOverlay.appendChild(_text);

        let btn = document.createElement("div");
        btn.classList.add("btn");
        btn.innerHTML = "Geschenkkorb anschauen<span class='icon'></span>";
        toastOverlay.appendChild(btn);
        document.body.appendChild(toastOverlay);


        btn.addEventListener("click", () => {
            document.location.hash = "#basket";
            toastOverlay.classList.remove("show");
        });
    }
    let textEl  = toastOverlay.querySelector(".text");

    let _btn  = toastOverlay.querySelector(".btn");

    if(state === "banner"){
        _btn.classList.add("hidden");
    }else{
        _btn.classList.remove("hidden");
    }
    toastOverlay.classList.remove("show");

    toastOverlay.classList.remove("info");
    toastOverlay.classList.remove("danger");
    toastOverlay.classList.remove("success");
    toastOverlay.classList.add(state);

    textEl.innerHTML = `<h1>${title}</h1><p>${text}</p>`;
    startBanner();
    setTimeout(() => {toastOverlay.classList.add("show");}, 10);

}
export function getBasketTotalPrice()
{
  let count = 0;
  for (const key in window.basket) {
    count += window.basket[key]?.item?.price;;
  }
 return count;
}


let toastTimeOuts = [];
export function addToBasket({item, srcPosition = null, destPosition = null, ignoreMax = false, trackStats=true}){

    while(toastTimeOuts.length>0){
        clearTimeout(toastTimeOuts.pop());
    }

    if(!ignoreMax && getBasketTotalPrice()+item.price>7){
        toast("Der Geschenkekorb ist voll", "Es können maximal 7 Rauten gesammelt werden", "danger");

        return false;
    }

    if(srcPosition != null && destPosition != null){

        let animationOverlay = document.getElementById("animationOverlay");
        if(!animationOverlay){
            animationOverlay = document.createElement("div");
            animationOverlay.id = "animationOverlay";
            animationOverlay.style.position = "fixed";
            document.body.appendChild(animationOverlay);
        }
        animationOverlay.innerHTML = "";

        for(let i=0; i<item.price;i++){
            let diamond = document.createElement("div");
            diamond.classList.add("diamond");
            diamond.classList.add("mask");
            diamond.style.setProperty("--src-x", srcPosition.x+"px");
            diamond.style.setProperty("--src-y", srcPosition.y+"px");
            diamond.style.setProperty("--dest-x", destPosition.x+"px");
            diamond.style.setProperty("--dest-y", destPosition.y+"px");
            diamond.style.setProperty("--duration", "1s");
            diamond.style.setProperty("--delay", (i*.1)+"s");
            animationOverlay.appendChild(diamond);
        }

        toastTimeOuts.push(setTimeout(() => {
            if(!isItemIdInBasket(item.id)){

                basket.push({item, ts: Date.now()});
                //localStorage.setItem("basket", JSON.stringify(basket));
            }else{
                toast(
                    `${item.title} (${item.price}) ist bereits im Geschenkkorb` ,
                    ``,
                    "info");
                return true;
            }
            setTimeout(() => {
                renderBasket();
            },1000+item.price*100);
            let remaining = 7-getBasketTotalPrice();
            if(remaining>0)
                toastTimeOuts.push(setTimeout(()=>toast(
                    `${item.title} hinzugefügt` ,
                    `Sie können noch ${remaining}  weitere Rauten sammeln`,
                    "success"), 500));
            else
                toastTimeOuts.push(setTimeout(()=>toast(
                    `${item.title} hinzugefügt` ,
                    `Sie haben alle 7 Rauten gesammelt`,
                    "success"), 500));
        }, 10+item.price*1));
        return true;
    }

    if(!isItemIdInBasket(item.id)){
        basket.push({item, ts: Date.now()});
    }
    renderBasket();

    return true;
}
export function isItemIdInBasket(id){
    return basket.find(entry=>entry.item.id === id) != null;
}
export function removeFromBasket(item){
    basket = basket.filter(entry => entry.item.id !== item.id);

    renderBasket();
}

export function getBasket(){
    return basket;
}
export function jumpToAnchor(anchor){
    let element = document.getElementById(anchor);
    //console.log("jumpToAnchor", anchor, element);
    if(element){
        element.scrollIntoView({behavior: "smooth"});
    }
}


function renderBasket() {
    window.basket = basket;
    document.dispatchEvent(new Event("basket_changed"));
}


const hashUpdated = (newHash, oldHash) => {


    hashes.push(newHash);
    let hashPage = null;
    for (const key in window.data.pages) {
        if(window.data.pages[key].slug_full === newHash){
            hashPage = window.data.pages[key];
            break;
        }
    }
    if(hashPage){
        showPackage(hashPage);

    }
    else{
        hidePackage();
    }

    //showPackage(item);
    if(newHash === "packages"){

    }
    else if(newHash === "start_"){
        hidePackage();
        hideBasket();
    }
    if(newHash === "spielregeln"){
        //showPage("spielregeln");
    }

}
syncContent().then(data => {
    window.data = data;
    addEventListener("hashchange", (event) => {

        let newHash = event.newURL.indexOf("#")>-1?event.newURL.split("#")[1]:'';
        let oldHash = event.oldURL.indexOf("#")>-1?event.oldURL.split("#")[1]:'';

        hashUpdated(newHash, oldHash);
    });
    initStart();
    initPackages(data);
    initBasket();
    initRegulations();
    initCheckOut();
    let newHash = window.location.href.indexOf("#")>-1?window.location.href.split("#")[1]:'';
    if(newHash != "")
    hashUpdated(newHash, "");
});



let hashes = [];
function getHash(offset){
    if(hashes.length>offset*-1){
        return hashes[hashes.length-1+offset];
    }
    else return "";
}

window.getHash = getHash;


var observer = new IntersectionObserver(onIntersection, {
    root: document.querySelector('#root'),   // default is the viewport
    threshold: .5 // percentage of target's visible area. Triggers "onIntersection"
  })


function onIntersection(entries, opts){
    entries.forEach(entry =>{
        if(document.location.hash !== "#start_" && document.location.hash !== "#pakete")
            return;
        if(entry.isIntersecting && Date.now()-lastChange>1000){

            if(document.location.hash !== "#start_")
                document.location.hash = "#start_";
        }
        if(!entry.isIntersecting){
            if(document.location.hash !== "#pakete")
                document.location.hash = "#pakete";
        }
    });
}

observer.observe( document.querySelector('#start') )


const main_start = document.getElementById("main_start");
const main_basket = document.getElementById("main_basket");
const main_package = document.getElementById("main_package");
const main_regulations = document.getElementById("main_regulations");
const main_checkout = document.getElementById("main_checkout");
let lastChange = Date.now();
const hashChanged = () => {
   // console.log("hashChanged", window.location.hash);
    if(!window.data?.pages){
        setTimeout(hashChanged, 100);
        return;
    }
    lastChange = Date.now();
    document.getElementById("btn_start_").classList.remove("active");
    document.getElementById("btn_pakete").classList.remove("active");
    document.getElementById("btn_spielregeln").classList.remove("active");
    document.getElementById("btn_basket").classList.remove("active");

    main_start.classList.add("hidden");
    main_basket.classList.add("hidden");
    main_package.classList.add("hidden");
    main_regulations.classList.add("hidden");
    main_checkout.classList.add("hidden");
    if(window.location.hash != "" && window.location.hash != "#start_" ){
        hideStart();
    }
    if(window.location.hash === "" || window.location.hash === "#start_" ){
        document.getElementById("btn_start_").classList.add("active");
        main_start.classList.remove("hidden");
        showStart();
        document.body.classList.remove("no-footer");
    }
    else if(window.location.hash === "#pakete"){
        document.getElementById("btn_pakete").classList.add("active");
        setTimeout(()=>jumpToAnchor("pakete"), 10);
        main_start.classList.remove("hidden");
        document.body.classList.remove("no-footer");
    }
    else if(window.location.hash === "#spielregeln"){
        document.getElementById("btn_spielregeln").classList.add("active");
        //showRegulations();
        setTimeout(()=>jumpToAnchor("spielregeln"), 10);
        setTimeout(()=>jumpToAnchor("spielregeln"), 100);
        main_regulations.classList.remove("hidden");
    }

    if(window.location.hash === "#checkout"){
        setTimeout(()=>jumpToAnchor("checkout"), 10);
        main_checkout.classList.remove("hidden");

        showCheckout();
        document.body.classList.add("no-footer");
    }else{

    }

    if(window.location.hash === "#basket"){
        document.getElementById("btn_basket").classList.add("active");
        document.getElementById("main_basket").classList.remove("hidden");
        document.body.classList.add("no-footer");
        showBasket();
        setTimeout(()=>jumpToAnchor("basket_"), 10);
    }else{
        hideBasket();
    }

    for(let i in window.data.pages){
        if("#"+window.data.pages[i].slug_full === window.location.hash){
            //showPackage(window.data.pages[i]);
            document.body.classList.add("no-footer");
            main_package.classList.remove("hidden");
            setTimeout(()=>jumpToAnchor("main_package_"), 10);
            setTimeout(()=>jumpToAnchor("main_package_"), 100);
            setTimeout(()=>jumpToAnchor("main_package_"), 200);


            break;
        }
    }
   // console.log("hashChanged", window.location.hash, window.data);

}


const linkedClicked = (event, target, link) => {


    if(target === "top")
        target = "Top";
    if(target === "bottom")
        target = "Bottom";
    sendNavigationStats(link, target);
}

window.linkedClicked = linkedClicked;

window.addEventListener("hashchange", hashChanged)
hashChanged();
init();

startBanner();