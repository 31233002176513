import './mediaGallerie.scss';

const slideTime = 50000;
let items=[];
let playBtn ;
export function create(_items)
{
  items = _items;

  let interval__;
  clearInterval(interval__);

  if(!items || items.length === 0)
    return null;

  let root = document.createElement('div');
  root.id = "mediaGallerie__";
  root.classList.add('mediaGallerie');


  let itemsContainer = document.createElement('div');
  itemsContainer.classList.add('media_items');
  itemsContainer.id = "media_items__";
  root.appendChild(itemsContainer);


  let dotsContainer = document.createElement('div');
  dotsContainer.classList.add('media_dots');
  root.appendChild(dotsContainer);
  let dots = [];

  const videoEnded = (event) => {

    //event.target.isPlaying = false;
    console.log("videoEnded", event.target);
    showNext();

  };

  document.addEventListener("visibilitychange", () => {
    let el = document.getElementById("media_items__");
    const percent = Math.round(100*el.scrollLeft/(el.scrollWidth-el.clientWidth))/100;
    let stepSize = 1/(items.length-1);
    let actualItem = Math.round(percent/stepSize);
    let el1 = document.getElementById("video"+actualItem);
    if (document.hidden) {
      console.log("hidden");
      if(!items[actualItem]?.isImage){

        el1.pause();



      }
    } else {

        //el1.play();
        console.log("visble", el1);
    }
  });

  items.forEach((item, index) => {
    const isImage = item.mime_type.indexOf("image") > -1;

    let el = document.createElement('div');
    el.classList.add('media_item');

    item.isImage = isImage
    if(isImage)
    {
      let img = document.createElement('img');
      img.src = item.url;
      img.loading = 'lazy';
      el.appendChild(img);

    }else{
      let video = document.createElement('video');
      video.src = item.url+"#t=0.001";
      video.controls = false;
      video.autoplay = false;
      video.playsInline = true;
      item.isPlaying = false;
      video.muted = false;
      video.id = "video"+index;

      el.appendChild(video);
      video.addEventListener('ended', videoEnded);
      video.addEventListener('pause', () => {playBtn.classList.remove("playing");console.log("paused");item.isPlaying = false;});
      video.addEventListener('playing', () => {playBtn.classList.add("playing");console.log("playing");item.isPlaying = true;});
      video.addEventListener('play', () => {playBtn.classList.add("playing"); console.log("playing");item.isPlaying = true;});


      let btn = document.createElement('div');
      btn.classList.add('play_btn');
      playBtn = btn;
      btn.onclick = () => {
        video.play();
        console.log("touchstart");
      };

      el.appendChild(btn);
    }

    itemsContainer.appendChild(el);

    const dot = document.createElement('div');
    dot.classList.add('media_dot');
    dot.id = "dot_"+index;
    dotsContainer.appendChild(dot);
    dots.push(dot);

    dot.onclick = () => {
      console.log("dot", index);
      let el = document.getElementById("media_items__");
      el.scrollLeft = index*el.clientWidth;
    //console.log(percent, items.length, stepSize, Math.round(percent/stepSize));
      updateScroll();
    }

  });


  const showNext = (first=false) => {
    let el = document.getElementById("media_items__");
    if(!el)
        return;
    const percent = Math.round(100*el.scrollLeft/(el.scrollWidth-el.clientWidth))/100;
    let stepSize = 1/(items.length-1);
    let actualItem = Math.round(percent/stepSize);
    //console.log("showNext", actualItem, items[actualItem]);

    if(!items[actualItem]?.isImage){

      //console.log(el, items[actualItem].isPlaying);
      if(items[actualItem].isPlaying)
        return;

    }

    //console.log("showNext", actualItem, items[actualItem]);

    if(first || actualItem >= items.length-1)
      actualItem = 0;
    else
      actualItem++;


    if(!items[actualItem]?.isImage){
      let el = document.getElementById("video"+actualItem);
      //el.play();

    }

    el.scrollLeft = actualItem*el.clientWidth;
    //console.log(percent, items.length, stepSize, Math.round(percent/stepSize));
    updateScroll();
  };

  const updateScroll = (event) => {

    let el = document.getElementById("media_items__");
    if(!el)
      return;
    const percent = Math.round(100*el.scrollLeft/(el.scrollWidth-el.clientWidth/2))/100;

    let nearstDistance = 1000000;
    let nearstItem = null;
    dots.forEach((item, index) => {
        const distance = Math.abs(percent - index/dots.length);

        if(distance<nearstDistance){
            nearstDistance = distance;
            nearstItem = item;
        }
    });



    dots.forEach((item, index) => {
      //let el = item;

      let el = document.querySelector("#"+item.id);

      if(!el){

      }
      else{

        if(item.id===nearstItem.id){
          clearTimeout(interval__);

          interval__ = setTimeout(() => {
            showNext();
          }, slideTime);
          if(!items[index]?.isImage){
            let el = document.getElementById("video"+index);
            //if(el)
            //  el.play();
          }
          el.classList.add('active');
        }
        else{
          el.classList.remove('active');
          if(!items[index]?.isImage){
            let el = document.getElementById("video"+index);
            if(el)
              el.pause();
          }
        }
      }
    });
  };

  let interval = setInterval(() =>
    {
        let el = document.querySelector(".media_items");
        if (el)
        {
            el.addEventListener("scroll", updateScroll);
            clearInterval(interval);
        }
    }, 100);

  clearTimeout(interval__);
  interval__ = setTimeout(() => {
    showNext();
  }, slideTime);
  setTimeout(() => {
    showNext(true);
  }, 100);


  return root;

}


export function addListeners(){
  let root = document.getElementById('mediaGallerie__');
  let itemsContainer = root.querySelector('.media_items');
  let dotsContainer = root.querySelector('.media_dots');

  items.forEach((item, index) => {
    const isImage = item.mime_type.indexOf("image") > -1;
    let el = itemsContainer.querySelector('.media_item:nth-child('+(index+1)+')');
    if(isImage)
    {
      let img = el.querySelector('img');
    }else{
      let video = el.querySelector('video');
      let btn = el.querySelector('.play_btn');
      playBtn = btn;
      video.preload="metadata"
      video.type="video/mp4"
      //video.addEventListener('ended', videoEnded);
      video.addEventListener('pause', () => {playBtn.classList.remove("playing");console.log("paused");item.isPlaying = false;});
      video.addEventListener('playing', () => {playBtn.classList.add("playing");console.log("playing");item.isPlaying = true;});
      video.addEventListener('play', () => {playBtn.classList.add("playing"); console.log("playing");item.isPlaying = true;});
      //video.preload = "none";
      video.style.setProperty("pointer-events", "all !important");
      btn.addEventListener('touchstart', () => {
        video.play();
        console.log("touchstart");
      });
      video.onclick = () => {
        video.play();
      };
    }

    const dot = dotsContainer.querySelector('.media_dot:nth-child('+(index+1)+')');
    dot.onclick = () => {
      let el = document.getElementById("media_items__");
      el.scrollLeft = index*el.clientWidth;
      //updateScroll();
    }
  });
}