import { addToBasket, getBasketTotalPrice } from '../../app';
import { show as showPackage } from '../package/package';
import { create } from '../package_item/package_item';
import { dataLayerEvents, sendStats } from '../stats';
import './packages.scss';
const root = document.getElementById('packages');
const package_filter = document.getElementById('package_filter');
const package_dots = document.getElementById('package_dots');
const packages = document.getElementById('package_items');



const updateScroll = (event) => {

  const percent = Math.round(100*event.target.scrollLeft/(event.target.scrollWidth-event.target.clientWidth/2))/100;

  let nearstDistance = 1000000;
  let nearstItem = null;
  visibleItems.forEach((item, index) => {
      const distance = Math.abs(percent - index/visibleItems.length);

      if(distance<nearstDistance){
          nearstDistance = distance;
          nearstItem = item;
      }
  });

  visibleItems.forEach((item, index) => {
    let el = document.getElementById("dot_"+item.id);
    if(!el){

    }
    else{
      if(item===nearstItem){
        el.classList.add('active');


        visibleItems[index].el.classList.add("active");
      }
      else
      {
        el.classList.remove('active');
        visibleItems[index].el.classList.remove("active");
        //visibleItems[index].classList.remove("active");
      }
    }
  });
};

packages.addEventListener("scroll", updateScroll);

let categories = [];
let items =  [];
let visibleItems = [];
let selectedCategories = [];

export function init(data)
{
  window.lastSelectedCategoryNames = "Alle Produkte";
  data.root.children.forEach(page => {
    categories.push(page);
  });

  categories.forEach(category => {
      category.children.forEach(item => {
        if(item.is_free){
          item.stats_category = 'Initial';
          item.list_index = 0;
          addToBasket({item, srcPosition: null, destPosition: null, ignoreMax: true});

          sendStats({evnt:dataLayerEvents.addToCart, items: [item]});
        }else{
          //debug test
          //addToBasket(item, null, null, true);
        }
        items.push(item);
      });
  });


  const selectCategory = (category_id) => {
    if(selectedCategories.indexOf(category_id)>-1)
      selectedCategories = selectedCategories.filter(id => id !== category_id);
    else{
      selectedCategories.push(category_id);

    }



    visibleItems = [];
    items.forEach(item => {
        if(selectedCategories.length===0 || selectedCategories.indexOf(item.parent)>-1){
            visibleItems.push(item);
            item.el.style.display = 'block';
        }else{
            item.el.style.display = 'none';
        }
    });

    let lastSelectedCategory = selectedCategories.indexOf(category_id)>-1?categories.find(category => category.id === category_id):null;

    if(lastSelectedCategory){
      let categoryNames = selectedCategories.map(id => categories.find(category => category.id === id).title);
      window.lastSelectedCategoryNames = categoryNames.join(", ");
    }else{
      window.lastSelectedCategoryNames = "Alle Produkte";


    }
    sendStats({evnt:dataLayerEvents.viewItemList, items: visibleItems, category: window.lastSelectedCategoryNames });

    package_dots.innerHTML="";
    visibleItems.forEach(item => {
        const dot = document.createElement('div');
        dot.id = "dot_"+item.id;
        package_dots.appendChild(dot);
    });

    visibleItems.forEach((item, index) => {
      item.list_index = index;
      item.stats_category = window.lastSelectedCategoryNames;
    });


    categories.forEach(category => {
        if(selectedCategories.indexOf(category.id)>-1){
            category.el.classList.add('active');
        }else{
            category.el.classList.remove('active');
        }
    });
  }

  packages.innerHTML="";
  package_filter.innerHTML="";
  package_dots.innerHTML="";

  categories.forEach(category => {
      let categoryDiv = document.createElement('div');
      category.el = categoryDiv;
      categoryDiv.addEventListener('click', () => {selectCategory(category.id)});
      categoryDiv.classList.add('category');
      categoryDiv.innerHTML = `<div class="title">${category.title}</div>`;
      package_filter.appendChild(categoryDiv);
  });

  visibleItems = [];
  items.forEach((item, index) => {
      let itemDiv = create(item, selectedCategories?.length===0?"Alle Produkte":null);
      item.list_index = index;
      item.stats_category = window.lastSelectedCategoryNames;
      packages.appendChild(itemDiv);

      const dot = document.createElement('div');
      dot.id = "dot_"+item.id;
      package_dots.appendChild(dot);

      visibleItems.push(item);
  });
}

function addPackageToBasket(clickedElement, item_id){
  let target = document.getElementById('basket');
  let srcPos = clickedElement.getBoundingClientRect();
  let targetPos = target.getBoundingClientRect();

  targetPos = {
    x: targetPos.x + targetPos.width/2,
    y: targetPos.y + targetPos.height/2
  };

  srcPos = {
    x: srcPos.x + 10,
    y: srcPos.y + srcPos.height/2
  }

  const item = items.find(item => item.id === item_id);

  //let count = getBasketTotalPrice();

  //console.log("addPackageToBasket", {...item}, srcPos, targetPos);
  let res = addToBasket({item:{...item}, srcPosition: srcPos, destPosition: targetPos, ignoreMax: false});
  return res;
  //if(res || res === undefined)
    //sendStats(dataLayerEvents.addToCart, [item], selectedCategories.length===0?"Alle Produkte":null);
}
window.addPackageToBasket = addPackageToBasket;
