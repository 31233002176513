import { getBasketTotalPrice } from '../../app.js';
import { toggle as toggleBasket } from '../basket/basket.js';
import {toggle as toggleMenu} from './../menu/menu.js';
import './header.css';
import './header.scss';
let root = document.getElementById('header');
let menuBtn = root.getElementsByClassName('menu')[0];
let basket =   document.getElementById('basket');
let basketBadge = basket.getElementsByClassName('badge')[0];


const updateBasketCount = ()=>
{
  let count = getBasketTotalPrice();



  if(count>0){
    basket.classList.remove('empty');
    basketBadge.classList.remove("animated");
    setTimeout(() => {
      basketBadge.classList.add("animated");
      basketBadge.innerHTML = `<span>${count}</span>`;
    }, 100);

  }
  else{
    basket.classList.add('empty');
    basketBadge.innerHTML =  `<span>${0}</span>`;
  }
}

export const init = () =>
{
  root = document.getElementById('header');
  menuBtn = root.getElementsByClassName('menu')[0];
  basket =   document.getElementById('basket');
  menuBtn.addEventListener('click', () => {
    console.log("toggle menu");
    toggleMenu()
  });
  basket.addEventListener('click', () => {
    toggleBasket()
  });

  document.addEventListener("basket_changed", (e)=>{updateBasketCount()}, false);
  updateBasketCount();
}
