//https://vbh70.domeniceau.de/wp-json/wp/v2/pages?per_page=100
//const url = 'https://vbh70.domeniceau.de/wp-json/wp/v2/pages?per_page=100'
//const url = 'https://edit.viebrockhaus.de/wp-json/wp/v2/pages?per_page=100'
const url = 'https://edit.viebrockhaus.de/wp-json/wp/v2/pages?per_page=100&_fields=id,modified,slug,type,title,menu_order,parent,meta,acf,%20content';

const syncContent = async () => {
    console.log("syncContent")
    const response = await fetch(url)
    const data = await response.json()


    const pages = [];
    data.sort((a, b) => a.menu_order - b.menu_order);
    data.forEach(page => {
        let p = new Page(page);
        pages.push(p);

    });

    //console.log(data);

    const db = {};
    db.pages = pages;
    db.root = pages.find(page=>page.acf?.page_type === "categories");
    db.start = pages.find(page=>page.acf?.page_type === "start");
    db.basket = pages.find(page=>page.acf?.page_type === "basket");
    db.regulations = pages.find(page=>page.acf?.page_type === "gameregulations");
    db.checkout = pages.find(page=>page.acf?.page_type === "checkout");
    addChildrenToPage(db.root, pages);


    let cats = db.root?.children || [];
    for(let i in cats){
      for(let j in cats[i].children){
        cats[i].children[j].categoryName = cats[i].title;
      }
    }
    return db;
};


const addChildrenToPage = (page, allPages) =>{
  let children = allPages.filter(p => p.parent === page.id);
  if(children.length > 0){
    page.children = children;

    page.slug_full = page.slug;
    children.forEach(child => {
      child.slug_full = page.slug+"/"+child.slug;
      addChildrenToPage(child, allPages);
    });
    //page.children.sort((a, b) => a.menu_order - b.menu_order);
  }
}

class Page{
    constructor(raw){
      //console.log(raw)
        this.id = raw.id;
        this.title = raw.title.rendered;
        this.slug = raw.slug;
        this.content = raw.content.rendered;
        this.parent = raw.parent;

        this.modified = raw.modified;
        this.meta = raw.meta;
        if(raw.acf?.kosten)
          this.price = raw.acf?.kosten;
        this.acf = raw.acf;
        this.children = [];
        this.menu_order = raw.menu_order;

        this.thumb_image = raw.acf.vorschaubild;
       // console.log("thumb_image", raw.acf)
        if(raw.acf["vorschaubild-animated"])
          this.thumb_image_animated = raw.acf["vorschaubild-animated"];

        this.thumb_subtitle = raw.acf["thumb-subtitle"];
        this.thumb_title = raw.acf["thumb-title"];

        let factsText = raw.acf["kurzfacts"];
        if(factsText){
          factsText = factsText.split("\t").join("");
          factsText = factsText.split("\r\n\r\n").join("<br/>");
          factsText = factsText.split("\r\n").join(" ");
        }
        this.thumb_facts = factsText?.split("•") || [];
        this.thumb_facts = this.thumb_facts.filter(fact => fact != "");



        this.detail_title = raw.acf["detail-title"];
        this.detail_subtitle = raw.acf["detail-subtitle"];
        this.detail_text = raw.acf["detail-text"];



        this.og = {
          description:raw.acf["og-description"],
          image:raw.acf["og_image"]
        };

        this.mediagallerie = raw.acf.mediagallerie;
        this.type = raw.acf?.page_type;


        this.price = parseInt(raw.acf.kosten);
        this.is_free = raw.acf.freebie;

        if(raw.acf.video_desktop?.url)
          this.videoDesktop = raw.acf.video_desktop?.url;
        if(raw.acf.video_mobile?.url)
          this.videoMobile = raw.acf.video_mobile?.url;
        if(raw.acf.page_image)
          this.pageImage = raw.acf.page_image;
        if(raw.acf.banner_title || raw.acf.banner_text){
          this.banner_title = raw.acf.banner_title;

          this.banner_text = raw.acf.banner_text;

        }
        if(raw.acf.banner_image){
          this.banner_image = raw.acf.banner_image;

        }

    }
}

module.exports = {syncContent};