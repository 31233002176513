
export const createPage = (pageModel) => {
  //console.log(pageModel)
  const container = document.createElement('div');
  container.classList.add('page-container');
  if(pageModel.banner_image){
    const banner = document.createElement('div');
    banner.classList.add('banner-image');
    banner.style.backgroundImage = `url(${pageModel.banner_image})`;
    container.appendChild(banner);
  }
  let page = document.createElement('div');
  page.classList.add('spage');
  container.appendChild(page);

  let leftText = document.createElement('div');
  leftText.classList.add('left-text');
  page.appendChild(leftText);



  leftText.innerHTML = pageModel.content;

  if(pageModel.pageImage){
    page.classList.add('with-bg');
    let rightImage = document.createElement('div');
    rightImage.classList.add('right-image');
    page.appendChild(rightImage);

    let img = new Image();
    img.src = pageModel.pageImage;
    rightImage.appendChild(img);

  }

  return container;
};
