import { getBasket, getBasketTotalPrice, isItemIdInBasket, removeFromBasket } from '../../app';
import { addListeners, create as createGallerie } from '../mediaGallerie/mediaGallerie';
import { dataLayerEvents, sendStats } from '../stats';

import './package.scss';

let root = document.getElementById('packagePage');
let container = document.getElementById('packagePageContainer');
let main_package;

if(!document.getElementById('packagePage'))
{
  main_package = document.getElementById('main_package');
  root = document.createElement('div');
  root.id = "packagePage";
  //root.classList.add('page');
  main_package.appendChild(root);
  container = document.createElement('div');
  container.id = "packagePageContainer";
  root.appendChild(container);

}

export function toggle()
{
  root = document.getElementById('packagePage');


  if(root.classList.contains('show'))
    hide();
  else
    show();
}
export function show(item, origin = null)
{
  root = document.getElementById('packagePage');
  container = document.getElementById('packagePageContainer');
  root.innerHTML = '';

  let anchor = document.createElement('div');
  anchor.id = "main_package_";
  anchor.classList.add('anchor');
  root.appendChild(anchor);


  root.appendChild(container);
  container.innerHTML = '';


  if(origin === 'basket'){
    root.classList.add('from_basket');
  }
  else{
    root.classList.remove('from_basket');
  }

  let pckg = item;
  const el = document.createElement('div');
  el.classList.add('item');
  if(pckg.is_free)
    el.classList.add('free');


  container.innerHTML += `<div class="galleryContainer placeholder img" style="--src=url(${pckg.image})"></div>`;

  let mediaGallerie = createGallerie(pckg.mediagallerie);
  if(mediaGallerie)
    container.querySelector(".galleryContainer").appendChild(mediaGallerie)



  let actionBtn = isItemIdInBasket(item.id) ? `<div class="actionBtn minus"><span class="icon basketMinus"></span>Jubiläumspaket entfernen</div>` : `<div class="actionBtn plus"><span class="icon basketPlus"></span>Jubiläumspaket hinzufügen</div>`;

  let footer = `<div class="footer">${actionBtn} <div class="backBtn" onclick="window.history.back()"><span class="icon back"></span>Zurück</div></div>`;

//  console.log(pckg);

  el.innerHTML = ``;
  el.innerHTML += `<div class="text">
    <div class="row">
     <div class="price"><div class="diamonds">${new Array(pckg.price+1).map(i=>i).join("<div class='mask'></div>")}</div> ${pckg.price===0?"<b>inklusive</b>":"<b>"+pckg.price+"</b> <span> Raute"+(pckg.price>1?"n":"")+"</span>"}  </div>
    </div>
    <h2 class="row">${pckg.detail_subtitle||""}</h2>


    <h1 class="row">${pckg.detail_title}</h1>
    <div class="row description">${pckg.detail_text}</div>

    ${window.device === 'desktop'?footer:''}
  </div>`;


  container.appendChild(el);


  if(window.device === 'mobile')
    root.innerHTML += footer;

  let elements = root.querySelectorAll('.actionBtn');

  let btns = [elements[0]];
  if(elements.length>1)
    btns.push(elements[1]);

  btns.forEach(b=>
    b.addEventListener('click', (event) => {
    if(isItemIdInBasket(item.id)){
      removeFromBasket(item);
      //sendStats(dataLayerEvents.removeFromCart, [item]);
      sendStats({evnt:dataLayerEvents.removeFromCart, items: [item]});
      setTimeout(()=>show(item),50);
    }
    else{
      let res = window.addPackageToBasket(event.target, item.id);

      if(res)
        sendStats({evnt:dataLayerEvents.addToCart, items: [item], category:window.lastSelectedCategoryNames});

      setTimeout(()=>show(item),2000);
    }


  })
);
  root.classList.add('show');

  setTimeout(() => {
    addListeners();
  },100)
  sendStats({evnt:dataLayerEvents.viewItem, items: [item], category:window.lastSelectedCategoryNames});
}


export function hide()
{
  //container = document.getElementById('packagePageContainer');

  root.classList.remove('show');
  container.innerHTML = "";

  let video = root.querySelector('video');
  if(video)
    video.pause();
}

