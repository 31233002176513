import { createPage } from '../page';
import './regulations.css';

let root;
const main_regulations = document.getElementById('main_regulations');
//this.banner_title
let text = document.getElementById('starttext');


export function init()
{
  main_regulations.innerHTML = '';

  let anchor = document.createElement('div');
  anchor.classList.add('anchor');
  anchor.id = 'spielregeln';
  main_regulations.appendChild(anchor);


  root = document.createElement('div');
  root.id = "regulationsPage";

  root.classList.add('mpage');
  main_regulations.appendChild(root);

  const page = createPage(window.data.regulations);

  root.appendChild(page);

  let spage = page.querySelector('.spage');


  let buttons = document.createElement('div');
  buttons.classList.add('buttons');
  root.appendChild(buttons);

  let btn = document.createElement('a');
  btn.classList.add('btn');
  btn.href = '#pakete';
  btn.innerHTML = 'Zu den Jubiläumspaketen';
  buttons.appendChild(btn);

  let btnBack = document.createElement('a');

  btnBack.classList.add('backBtn');
  btnBack.classList.add('_desktop');
  btnBack.href = '#';
  btnBack.innerHTML = '<div class="icon"></div>Zurück';
  btnBack.addEventListener('click', (e) => {
    e.preventDefault();
    window.history.back();
  });
  buttons.appendChild(btnBack);
 // resized();

  //document.addEventListener('deviceChanged', resized);
}



