import { getBasket, getBasketTotalPrice, jumpToAnchor, removeFromBasket, toast } from '../../app';
import { show as showPackage } from '../package/package';
import { create } from '../package_item/package_item';
import { createPage } from '../page';
import { dataLayerEvents, sendStats } from '../stats';
import './basket.scss';

let root = document.getElementById('basketPage');
let container = document.getElementById('basketPageContainer');
let items = document.getElementById('basket_items');
let hubspot = document.getElementById('hubspot-checkout');
let hubspotAnchor = document.getElementById('hubspot-anchor');
let checkoutRow = document.getElementById('checkoutRow');
let checkoutBtn = document.getElementById('checkoutBtn');

let main_basket;
export let initilized = false;

export const init = () =>{

  main_basket = document.getElementById('main_basket');
  main_basket.innerHTML = "";





  root = document.createElement('div');
  root.id = "basketPage";
  root.classList.add('page');
  main_basket.appendChild(root);
  container = document.createElement('div');
  container.id = "basketPageContainer";
  root.appendChild(container);


  let anchor = document.createElement('div');
  anchor.classList.add('anchor');
  anchor.id = 'basket_';
  main_basket.appendChild(anchor);


  container.classList.add('mpage');
  if(window.data.basket.content && window.data.basket.content.indexOf("<ol>") === -1)
    window.data.basket.content += `
  <p><b>So sichern Sie sich Ihre kostenlosen Jubiläumspakete in 4 Schritten, völlig unverbindlich und nur für kurze Zeit!</b>

  <ol>
    <li value="1">Hier die Auswahl Ihrer Jubiläumspakete überprüfen oder ändern.</li>
    <li value="2"><div>Klicken Sie auf den roten Button: <span class="red">Jetzt Jubiläumspakete sichern!</span></div></li>
    <li value="3">Kontaktformular jetzt ausfüllen und absenden.</li>
    <li value="4">Ihre Jubiläumspakete sind reserviert! Das Haus Ihres Lebens wartet – wir melden uns und begleiten Sie dorthin.</li>

  </ol>
  </p>`
  const page = createPage(window.data.basket);

  container.appendChild(page);

  let spage = page.querySelector('.spage');
  let buttons = document.createElement('div');
  buttons.classList.add('buttons');
  if(window.device === "desktop")
    container.appendChild(buttons);

  let btn = document.createElement('a');
  btn.classList.add('btn');
  btn.href = '#checkout';
  btn.innerHTML = 'Jetzt Jubiläumspakete sichern!';
  buttons.appendChild(btn);

  let btnBack = document.createElement('a');

  btnBack.classList.add('backBtn');
  btnBack.href = '#';
  btnBack.innerHTML = '<div class="icon"></div>Zurück';
  btnBack.addEventListener('click', (e) => {
    e.preventDefault();
    window.history.back();
  });
  if(window.device === "desktop")
    buttons.appendChild(btnBack);


  items = document.createElement('div');
  items.id = "basket_items";
  items.classList.add('package_items');
  container.appendChild(items);

  if(window.device === "mobile"){
    let footer = document.createElement('div');
    footer.classList.add('footer');
    footer.appendChild(buttons);
    root.appendChild(footer);


  }
  initilized = true;
}

function addHubspot(){
  if(!window.TRACKING)
    return;
  /*
  let el = document.getElementById('hs-script-loader');

  if(!el){

    let script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("charset", "utf-8");
    script.setAttribute("id", "hs-script-loader");
    script.setAttribute("src", "//js-eu1.hsforms.net/forms/embed/v2.js");
    document.getElementsByTagName("head")[0].appendChild(script);
  }
  */

}
addHubspot();

export function toggle()
{
  root = document.getElementById('basketPage');

  if(window.device === "mobile" && (root.classList.contains('show') || document.location.hash === "#basket")){

    window.history.back();
  }
  else{

    document.location.hash = "#basket";
    try{

      hubspot.classList.remove('show');
      checkoutBtn.style.display = 'block';
    }
    catch(ex){}
  }
}

function renderBasket()
{
  if(!initilized)
    return;
  console.log("show basket", document.getElementById('basketPage'));


  if(root.parentNode === main_basket){
    main_basket.removeChild(root);
  }


  main_basket.appendChild(root);


  const basket = [...getBasket()].sort((a, b) => b.ts - a.ts);

  root = document.getElementById('basketPage');
  container = document.getElementById('basketPageContainer');
  items = document.getElementById('basket_items');

  items.innerHTML = '';

  basket.forEach((item, index) => {

    let pckg = item.item;
    const el = create(pckg);
    items.appendChild(el);
  });


  checkoutRow = document.getElementById('checkoutRow');
  checkoutBtn = document.getElementById('checkoutBtn');

  hubspot = document.getElementById('hubspot-checkout');

  root.classList.add('show');
  main_basket.classList.remove("hidden");
  sendStats({evnt:dataLayerEvents.viewCart, items:basket.map(i=>i.item), category:"Basket"});
}


export function show(){
  renderBasket();


  document.addEventListener('basket_changed', renderBasket);
}


export function hide()
{
  document.removeEventListener('basket_changed', renderBasket);
  if(!initilized)
    return;
  root = document.getElementById('basketPage');
  items = document.getElementById('basket_items');

  root.classList.remove('show');


  items.innerHTML = "";
}

