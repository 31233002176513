import './menu.css';
const root = document.getElementById('menu');

export function init()
{
  root.addEventListener('click', (e) => {
    close();
  });
}




export function toggle(){
  console.log(document.body.classList);
  if(document.body.classList.contains('drawer-opened'))
    close();
  else
    open();
}
export function close(){
  document.body.classList.remove('drawer-opened');
}
export function open(){
  document.body.classList.add('drawer-opened');
}