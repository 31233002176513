import parseAPNG from 'apng-js';

export async function createPlayer(url, playerCreatedCallback, canvas, autoplay=false){
	let player;
	const playbackRate = 1;


	let response = await fetch(url+"?dfd");

  let data = await response.blob();
  //console.log(data);
  let metadata = {type: 'image/png'};
  let file = new File([data], "test.png", metadata);

	//console.log(file);
  /*if (player) {
    player.stop();
  }*/

  const reader = new FileReader()
  reader.onload = () => {
    const apng = parseAPNG(reader.result)
    if (apng instanceof Error) {
			console.error(apng.message)
      return
    }
    apng.numPlays = 1
    apng.createImages().then(() => {
      //const canvas = document.createElement('canvas')
      canvas.width = apng.width
      canvas.height = apng.height

     // parentElement.appendChild(canvas)

      apng.getPlayer(canvas.getContext('2d'), autoplay, true).then(p => {
        player = p
        player.playbackRate = playbackRate
        player.canvas = canvas;
        const em = player.emit;
        player.emit = (event, ...args) => {
				  em.call(player, event, ...args);
        }
				if(playerCreatedCallback)
					playerCreatedCallback(player);
        //player.play();
				//player.on('end',(e)=>{console.log('end');})
				//player.on('stop',(e)=>{console.log('stop');})
      })
    })

  }
  reader.readAsArrayBuffer(file)
}
