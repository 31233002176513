
export const dataLayerEvents = {
  viewItemList: "view_item_list",
  selectItem: "select_item",
  viewItem: "view_item",
  addToCart:"add_to_cart",
  removeFromCart: "remove_from_cart",
  viewCart: "view_cart",
  beginCheckout: "begin_checkout",
  generateLead: "generate_lead",
  navigation: "navigation",
}
const dataLayerActive = true;


const createItem = (item, itemlistnameInHead) =>{
  if(!item.acf)
    {
      console.error("createItem", item);
      return;
    }
  const el =  {
    item_id: item.acf["sku"],
    item_name: item.acf["thumb-title"],
    index: (item.list_index || 0)+1, //Position in der Produktliste
    item_category: item.categoryName,
    item_virtual_currency: item.price, //Anzahl Rauten
    quantity: 1
  }
  if(!itemlistnameInHead)
    el.item_list_name = item.stats_category

  return el;
}
export const sendStats = ({evnt, items, category, userData}) => {
  //evnt = ""view_item_list" || "select"

  let el = {
    event: evnt,
    ecommerce: {
      item_list_name: category||items[0].stats_category,
      items: []
    }
  };



  let itemlistnameInHead = true;
  if(evnt === dataLayerEvents.addToCart || evnt === dataLayerEvents.removeFromCart || evnt === dataLayerEvents.viewCart || evnt === dataLayerEvents.beginCheckout || evnt === dataLayerEvents.generateLead){
    itemlistnameInHead = false;

  }

  if(!itemlistnameInHead){
    delete el.ecommerce.item_list_name;
  }


  for(let i = 0; i < items.length; i++){
    el.ecommerce.items.push(createItem(items[i], itemlistnameInHead));
  }

  if(evnt === dataLayerEvents.generateLead){
    el.virtual_currency = 6;
    el.email = userData?.email;
    el.first_name = userData?.firstname;
    el.last_name = userData?.lastname;
    el.phone_number = userData?.phone;

  }

  if(!dataLayerActive)
    return;

  console.log("sendStats to dataLayer", el, el.ecommerce.items);

  dataLayer.push(el);

}

export const sendNavigationStats = (link, type="Top") => {
  //type = "Top" || "Footer"
  let el = {
    event: dataLayerEvents.navigation,
    navigation_type: type,
    navigation_name: link
    }

  if(!dataLayerActive)
    return;
  dataLayer.push(el);
}