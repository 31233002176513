
import { removeFromBasket } from '../../app';
import { createPlayer } from '../APNGPlayer';
import { dataLayerEvents, sendStats } from '../stats';
import './package_item.scss';

export function create(item, selectedCategory)
{


  let itemDiv = document.createElement('div');
  item.el = itemDiv;
  itemDiv.classList.add('item');
  itemDiv.classList.add('package_item');

  if(item.is_free)
    itemDiv.classList.add('free');

  let diamonds = new Array(item.price+1).map(i=>i).join("<div class='mask'></div>");
  if(item.price===0)
    diamonds = "<div class='mask'></div>";
  itemDiv.innerHTML = `
  <div>
  <div class="item-price"><div class="diamonds">${diamonds}</div> ${item.price===0?"<b>INKLUSIVE</b>":"<b>"+item.price+"</b> <span> Raute"+(item.price>1?"n":"")+"</span>"}  </div>

    <div class="item-img-animated">
      <img src="${item.thumb_image}" alt="img" />
    </div>
    <div class="texts">
      <div class="block">
        <div class="subtitle">${item.thumb_subtitle}</div>
        <div class="title">${item.thumb_title}</div>
        <div class="facts">${item.thumb_facts.map(f=>"<li><div class='mask'></div>"+f+"</li>").join("")}</div>

        <div class="btns">
          <div class="moreBtn"><div class='icon'></div> Details ansehen</div>
          <div class="addToCart ${item.price===0?'hidden':''}"><div class='icon'></div> Jubiläumspaket hinzufügen</div>

        </div>
      </div>
    </div>
    </div>
  `;

  if(item.thumb_image_animated){
    let img_animated = itemDiv.querySelector('.item-img-animated');
    let img = img_animated.querySelector('img');


    let canvas = document.createElement('canvas');
    canvas.classList.add('apng');
    img.style.setProperty('pointer-events', 'all');
    itemDiv.ontouchstart = itemDiv.onmouseover=()=>{
      console.log('touchstart')

      if(canvas.playerloaded){
        if(canvas.player){
          canvas.player.play();
          img.classList.add('hidden');
        }
        return;
      }
      canvas.playerloaded=true;
      createPlayer(item.thumb_image_animated, (player) => {

        canvas.player = player;
        img_animated.appendChild(canvas);
        img_animated.classList.remove('hidden');
        img.classList.add('hidden');

        itemDiv.onmouseleave=()=>{

          player.stop()
          //img.classList.remove('hidden');
          //img_animated.classList.add('hidden');
        };

      }, canvas, true);

    };

  }
  const cardActionBtn = itemDiv.querySelector('.addToCart');
  const basketChanged = () => {
    let isInBasket = window.basket.find(basketItem => basketItem.item.id === item.id) != null;
    if(isInBasket){
      cardActionBtn.innerHTML = `<div class='icon minus'></div> Jubiläumspaket entfernen`;
    }else{
      cardActionBtn.innerHTML = `<div class='icon'></div> Jubiläumspaket hinzufügen`;
    }

  }
  basketChanged();
  document.addEventListener("basket_changed", basketChanged);
  itemDiv.querySelector('.moreBtn').addEventListener('click', () => {
    sendStats({evnt:dataLayerEvents.selectItem, items: [item], category:window.lastSelectedCategoryNames});

    window.location.hash = item.slug_full;
  });
  cardActionBtn.addEventListener('click', (event) => {
    let isInBasket = window.basket.find(basketItem => basketItem.item.id === item.id) != null;

    if(isInBasket){
      removeFromBasket(item);
      sendStats({evnt:dataLayerEvents.removeFromCart, items: [item], category:window.lastSelectedCategoryNames});
    }
    else{
      item.stats_category = window.lastSelectedCategoryNames;


      let res = window.addPackageToBasket(event.target, item.id, window.lastSelectedCategoryNames);
      if(res)
        sendStats({evnt:dataLayerEvents.addToCart, items: [item], category:window.lastSelectedCategoryNames});
    }
  });
  return itemDiv;
}